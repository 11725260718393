import React from "react";
import Gallery1 from "../assets/gallery_1.jpg";
import Gallery2 from "../assets/gallery_2.jpg";
import Gallery3 from "../assets/gallery_3.jpg";
import Gallery4 from "../assets/gallery_4.jpg";
import Gallery5 from "../assets/gallery_5.jpg";
import Gallery6 from "../assets/gallery_6.jpg";
import Gallery7 from "../assets/gallery_7.jpg";
import Gallery8 from "../assets/gallery_8.jpg";
import Gallery9 from "../assets/gallery_9.jpg";
import Gallery10 from "../assets//realisations/gallery_10.jpg";
import Gallery11 from "../assets//realisations/gallery_11.jpg";
import Gallery12 from "../assets//realisations/gallery_12.jpg";
import Gallery13 from "../assets//realisations/gallery_13.jpg";
import Gallery14 from "../assets//realisations/gallery_14.jpg";
import Gallery15 from "../assets//realisations/gallery_15.jpg";
import Gallery16 from "../assets//realisations/gallery_16.jpg";
import Gallery17 from "../assets//realisations/gallery_17.jpg";
import Gallery18 from "../assets//realisations/gallery_18.jpg";
import Gallery19 from "../assets//realisations/gallery_19.jpg";
import Gallery20 from "../assets//realisations/gallery_20.jpg";



export default function Realisations() {
  const realisationsImages = [
    Gallery1,
    Gallery2,
    Gallery3,
    Gallery4,
    Gallery5,
    Gallery6,
    Gallery7,
    Gallery8,
    Gallery9,
    Gallery10,
    Gallery11,
    Gallery11,
    Gallery12,
    Gallery13,
    Gallery14,
    Gallery15,
    Gallery16,
    Gallery17,
    Gallery18,
    Gallery19,
    Gallery20
  ];
  return (
    <div className="realisations">
      <h3 className="text--bold-xl">Réalisations</h3>
      <div className="realisations__list">
        {realisationsImages.map((image, index) => (
          <a target="_blank" href={image} key={index}>
            <img
              key={index}
              className="realisations__list__img"
              alt="realisation"
              src={image}
            ></img>
          </a>
        ))}
      </div>
    </div>
  );
}
