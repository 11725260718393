import React from "react";
export default function ServiceCard(props) {
  return (
    <div className="services__card show-on-scroll " key={props.serviceName}>
      <img alt="serviceImg" src={props.img} className="services__card__img"/>
      <h3 className="text--bold-xl services__card__title">{props.serviceName}</h3>
      <ul>
        {props.description.map((desc,index) => (
          <li key={desc+index} style={{color:"var(--grey-8)"}}><h3 className="text--regular-lg">{desc}</h3></li>
        ))}
      </ul>
    </div>
  );
}
