import React from "react";
import logo from "../assets/logo.PNG";
import UserReview from "./user_review";
import {MdPersonPin} from "react-icons/md";

export default function Presentation() {
  return (
    <div className="presentation">
      <h3 className="text--bold-xl">Présentation</h3>
      <div className="presentation__description">
        <img alt="logo" src={logo} className="presentation__logo"></img>
        <p className="presentation__description__text">
          Je m'appelle Rémy Bouwens. <br />
          Je suis plombier, chauffagiste avec plus de 10 ans d'expérience dans
          le métier. <br />
          Je réalise des travaux et des réparations pour les chaudières,
          chauffages, climatisations, poëles, <br />
          sanitaires, ventilations. <br />
          Professionnel et à l'écoute du client, je suis également passionné par
          mon métier,
          <br />
          et je continue de suivre des formations pour rester aux normes des
          dernières installations. <br />
          Je me déplace dans un rayon de 30km autour de Ciney.
          <br />
        </p>
      </div>
      <div className="presentation__card">
        <MdPersonPin
          color="white"
          fontSize="large"
          style={{ marginBottom: "var(--spacing-2)" }}
        />
        <ul>
          <li>Frigoriste qualifié. (PAC, Vmc,...)</li>
          <li>Certification Ravelli. (Poeles, ...)</li>
          <li>Plus de 10 années d'expérience</li>
          <li>A l'écoute du client</li>
          <li>Professionnel</li>
        </ul>
      </div>
      <div className="presentation__reviews">
        <UserReview
          rating={5}
          review="Entreprise plus que sérieuse, très bonne communication, excellent suivi et très bon travail effectué par les patrons et leur super équipe. À recommander les yeux fermés. Encore un grand merci pour tout."
          username="PJ"
        />
        <UserReview
          rating={5}
          review="Installation d'un filtre à Osmose Inverse et d'une lampe difficile d'accès, très satisfait !"
          username="LQ"
        />
        <UserReview
          review="Super bon service de qualité encore merci pour votre rapidité 👍"
          rating={4.8}
          username="PM"
        />
      </div>
    </div>
  );
}
