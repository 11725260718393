import * as React from "react";
import { MapContainer , TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default function MapInfos() {
  const position = [50.312141, 5.11226];
  return (
    <MapContainer  center={position} zoom={17} style={{ height: "300px", width:"100%" }}>
      <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" />
      <Marker position={position}>
        <Popup>Localisation de mon Bureau.</Popup>
      </Marker>
    </MapContainer >
  );
}
