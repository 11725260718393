import React from "react";
import logo from "../assets/logo.PNG";
import MapInfos from "./map";
import { AiFillMail, AiFillPhone, AiFillCar } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
export default function Contact() {
  const openGoogleMaps = () => {
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=Allée+des+Foires+3,+Ciney&travelmode=car"
    );
  };
  return (
    <div className="contact">
      <h3 className="text--bold-xl">Contact</h3>

      <div className="contact__infos">
        <img alt="logo" src={logo} className="contact__logo"></img>

        <div className="contact__infosCard">
          <h3 className="text--regular-lg">Informations générales</h3>
          <div className="contact__divider" />
          <div className="contact-grid">
            <AiFillMail className="icon" />
            <a className="contact__link" href="mailto:info@remybouwens.be">
              info@remybouwens.be
            </a>
          </div>
          <div className="contact-grid">
            <AiFillPhone className="icon" />
            <a href="tel:0474475553" className="contact__link">
              0474475553
            </a>
          </div>
          <div className="contact-grid">
            <AiFillCar className="icon" />
            <a href="/" onClick={openGoogleMaps} className="contact__link">
              Allée des Foires 3, 5590 Ciney
            </a>
          </div>
          <div className="contact-grid">
            <ImLocation className="icon" />
            <span className="contact__link">TVA BE 0842.580.503</span>
          </div>
        </div>
      </div>
      <MapInfos />
    </div>
  );
}
