import React from "react";

export default function UserReview(props) {
  return (
    <div className="presentation__user-review" key={props.username}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <Avatar style={{ marginRight: "var(--spacing-2)" }}>
          {props.username}
        </Avatar>
        <Rating
          name="Google review"
          defaultValue={props.rating}
          precision={0.1}
          readOnly={true}
        /> */}
      </div>
      <p className="presentation__user-review__review">{props.review}</p>
    </div>
  );
}
