import React from "react";
import "./scss/styles.scss";
import { BrowserRouter } from "react-router-dom";
import NavigationMenu from "./components/navigation_menu/NavigationMenu";

export default function App() {
  return (
    <BrowserRouter>
        <NavigationMenu />
    </BrowserRouter>
  );
}
