import React from "react";
import {
  AiFillMail,
  AiFillPhone,
  AiOutlineCopyrightCircle,
} from "react-icons/ai";

export default function Footer() {
  return (
    <div className="footer">
      <div style={{ display: "flex" }}>
        <div className="footer__contact">
          <h3>Contactez nous</h3>
          <div className="footer-grid">
            <AiFillMail />
            <a
              className="footer__contact__link"
              href="mailto:info@remybouwens.be"
            >
              info@remybouwens.be
            </a>
          </div>
          <div className="footer-grid">
            <AiFillPhone />
            <a href="tel:0474475553" className="footer__contact__link">
              0474475553
            </a>
          </div>
          <div className="footer-grid">
            <h5 style={{ color: "white" }}>TVA</h5>
            <span className="footer__contact__link">BE 0842.580.503</span>
          </div>
        </div>
        <div className="footer__divider" />
      </div>

      <div className="footer__services">
        <span className="footer__services__link">Chauffage</span>
        <span className="footer__services__link">Ventilation</span>
        <span className="footer__services__link">Sanitaire</span>
        <span className="footer__services__link">Climatisation</span>
        <span className="footer__services__link">Poêles</span>
      </div>
      <div className="footer__divider" />
      <div
        style={{ display: "flex", alignItems: "center", alignSelf: "center" }}
      >
        <AiOutlineCopyrightCircle color="white" />
        <h5 style={{ color: "white", marginLeft: "var(--spacing-2)" }}>
          Site web réalisé par
          <a
            className="footer__contact__link"
            href="mailto:quiryswebdev@gmail.com.be"
          >
            QuirysWebDev
          </a>
        </h5>
      </div>
    </div>
  );
}
