import React, { useEffect  } from "react";
import ServiceCard from "./service_card";
import Chaudiere from "../assets/chaudiere.jpg";
import Poele from "../assets/poele.jpg";
import Climatisation from "../assets/climatisation.jpg";
import Depannage from "../assets/depannage.jpg";
import Entretien from "../assets/entretien.jpg";
import Sanitaire from "../assets/sanitaire.jpg";

export default function Services() {
 
  useEffect(() => {
    const callback = function (entries) {
        entries.forEach((entry) => {
          entry.target.classList.toggle("is-visible");
        });
      };
    const observer = new IntersectionObserver(callback);

    var targets = document.querySelectorAll(".show-on-scroll");
    targets.forEach(function (target) {
      observer.observe(target);
    });
  },[]);

  return (
    <div className="services">
      <h3 className="text--bold-xl">Services</h3>
      <div className="services__list">
        <ServiceCard
          img={Chaudiere}
          serviceName={"Chauffage"}
          description={[
            "Chaudières",
            "Pompes à chaleur",
            "Chauffages sol",
            "Radiateurs",
          ]}
        />
        <ServiceCard
          img={Sanitaire}
          serviceName={"Sanitaire"}
          description={["Chauffe-eau", "Salle de bain", "Wc", "Robinetterie"]}
        />
        <ServiceCard
          img={Entretien}
          serviceName={"Entretien"}
          description={[
            "Chaudière (mazout, gaz, pellets)",
            "Poêle (bois/pellets)",
            "VMC",
            "Pompe à chaleur",
            "Ramonnage",
          ]}
        />
        <ServiceCard
          img={Depannage}
          serviceName={"Dépannage"}
          description={["Fuite d'eau", "Plomberie", "Problèmes de chauffage"]}
        />
        <ServiceCard
          img={Poele}
          serviceName={"Poêles"}
          description={["Poêles à bois", "Poêles à pellets"]}
        />
        <ServiceCard
          img={Climatisation}
          serviceName={"Ventilation"}
          description={["Vmc (double/simple flux)", "Climatisation (Air Co)"]}
        />
      </div>
    </div>
  );
}
