import React from "react";

export default function Accueil() {
    function SendMail(){
        window.open("mailto:info@remybouwens.be");
    }
  return (
    <div className="accueil">
      <button onClick={SendMail} className="accueil__button">
        <h3 className="text--bold-lg">
          Demander un devis gratuit
        </h3>
      </button>
      <div className="accueil__description">
        <h3 className="accueil__description__text">
          Votre chauffagiste autour de Ciney.
        </h3>
      </div>
    </div>
  );
}
