import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import logoRemyBouwens from "../../assets/logoRemyBouwens.png";
import Accueil from "../accueil";
import Presentation from "../presentation";
import Services from "../services";
import Realisations from "../realisations";
import Contact from "../contact";
import Footer from "../footer";
import React, { useState, useEffect } from "react";

export default function NavigationMenu() {
  const [expanded, setExpanded] = useState(false);
  const [showBrand, setShowBrand] = useState(true);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(true);
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  function ScrollToElement(elementClass) {
    setExpanded(false);
    setShowBrand(false);
    var element = document.getElementsByClassName(elementClass)[0];
    element.scrollIntoView({ block: "start", behavior: "smooth" });
  }
  window.onscroll = function onUserScroll() {
    window.pageYOffset === 0 || window.screen.width < 800
      ? setShowBrand(true)
      : setShowBrand(false);
  };
  return (
    <div id="page">
      <Navbar
        expand="lg"
        expanded={expanded}
        sticky="top"
        className={"navigation" + (showBrand ? "" : " active")}
      >
        <Navbar.Brand
          as={Link}
          to="/"
          className={"brand" + (showBrand ? "" : " unactive")}
        >
          <img alt="logo" src={logoRemyBouwens} height="100" width="200" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            <NavItem onClick={() => ScrollToElement("accueil")}>
              <Nav.Link>
                {showBrand ? (
                  <h3 className="text--medium-l">Accueil</h3>
                ) : (
                  <h3 className="text--medium-l" style={{ color: "white" }}>
                    Accueil
                  </h3>
                )}
              </Nav.Link>
            </NavItem>
            <NavItem onClick={() => ScrollToElement("presentation")}>
              <Nav.Link>
                {showBrand ? (
                  <h3 className="text--medium-l">Présentation</h3>
                ) : (
                  <h3 className="text--medium-l" style={{ color: "white" }}>
                    Présentation
                  </h3>
                )}
              </Nav.Link>
            </NavItem>
            <NavItem onClick={() => ScrollToElement("services")}>
              <Nav.Link>
                {showBrand ? (
                  <h3 className="text--medium-l">Services</h3>
                ) : (
                  <h3 className="text--medium-l" style={{ color: "white" }}>
                    Services
                  </h3>
                )}
              </Nav.Link>
            </NavItem>
            <NavItem onClick={() => ScrollToElement("realisations")}>
              <Nav.Link>
                {showBrand ? (
                  <h3 className="text--medium-l">Réalisations</h3>
                ) : (
                  <h3 className="text--medium-l" style={{ color: "white" }}>
                    Réalisations
                  </h3>
                )}
              </Nav.Link>
            </NavItem>
            <NavItem onClick={() => ScrollToElement("contact")}>
              <Nav.Link>
                {showBrand ? (
                  <h3 className="text--medium-l">Contact</h3>
                ) : (
                  <h3 className="text--medium-l" style={{ color: "white" }}>
                    Contact
                  </h3>
                )}
              </Nav.Link>
            </NavItem>
            
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div id="contentSite">       
        <Accueil />
        <Presentation />
        <Services />
        <Realisations />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}
